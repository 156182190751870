<!--
 * @Description: 我的宝贝
 * @Author: 琢磨先生
 * @Date: 2022-05-17 16:56:07
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-03-12 13:23:41
-->
<template>
  <el-card class="box">
    <el-form ref="query" :model="query" :inline="true">
      <el-form-item label="关键字">
        <el-input v-model="query.q" placeholder="请输入名称"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" round @click="onSearch" icon="search">查询</el-button>
      </el-form-item>
    </el-form>
  </el-card>
  <el-card class="box data" v-loading="loading">
    <el-tabs v-model="query.status" @tab-change="statusChange">
      <el-tab-pane label="在售" name="2"></el-tab-pane>
      <el-tab-pane label="在库" name="3"></el-tab-pane>
      <el-tab-pane label="草稿" name="1"></el-tab-pane>
    </el-tabs>

    <el-table :data="tableData.data" border @sort-change="onSort">
      <el-table-column label="编号" prop="id" width="80"></el-table-column>
      <el-table-column label="主图" width="140">
        <template #default="scope">
          <el-image
            :src="scope.row.logo_url"
            style="width: 100px; height: 100px"
            :preview-src-list="[scope.row.logo_url]"
            fit="fill"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column label="类目" prop="category.full_name" width="200"></el-table-column>
      <el-table-column label="标题" prop="title" width="300"></el-table-column>
      <el-table-column label="售价" prop="id" width="140">
        <template #default="scope">
          <div>一口价：￥{{ scope.row.price }}</div>
          <div>sku：{{ scope.row.is_open_sku ? "已开启" : "" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="承诺发货时间" prop="id" width="120">
        <template #default="scope">
          <div>{{ scope.row.shipment_limit_second / 3600 }}小时</div>
        </template>
      </el-table-column>
      <el-table-column label="提供发票" prop="id" width="120">
        <template #default="scope">
          <div>{{ scope.row.is_provide_invoice ? "是" : "" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="保修" prop="id" width="120">
        <template #default="scope">
          <div>{{ scope.row.is_warranty ? "是" : "" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="7天理由退货" prop="id" width="120">
        <template #default="scope">
          <div>{{ scope.row.is_7day_return ? "是" : "" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="create_at" width="180"></el-table-column>

      <el-table-column label="操作" fixed="right" width="200">
        <template #default="scope">
          <el-button
            type="primary"
            link
            size="small"
            @click="onEdit(scope.row)"
            v-if="is_can_edit"
          >修改</el-button>
          <!-- <el-button type="text" size="small" @click="onDtl(scope.row)"
            >更新规格库存</el-button
          >

          <el-popconfirm
            :title="`您确定要${scope.row.status == 2 ? '下架' : '上架'}`"
            @confirm="onStatus(scope.row)"
          >
            <template #reference>
              <el-button
                type="text"
                v-if="scope.row.status == 2 || scope.row.status == 3"
                size="small"
                >{{ scope.row.status == 2 ? "下架" : "上架" }}</el-button
              >
            </template>
          </el-popconfirm>

          <el-button type="text" size="small" @click="onDtl(scope.row)"
            >修改详情</el-button
          >-->
          <el-popconfirm title="确定要删除？" @confirm="onDelete(scope.row)" v-if="is_can_del">
            <template #reference>
              <el-button type="danger" link size="small" class="text-danger">删除</el-button>
            </template>
          </el-popconfirm>
          <el-popconfirm
            title="确定要下架？"
            @confirm="onHaltSale(scope.row)"
            v-if="scope.row.status == 2 && is_can_halt"
          >
            <template #reference>
              <el-button type="primary" link size="small">下架</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      v-if="tableData.counts > 0"
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :current-page="query.pageIndex"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="tableData.counts"
    ></el-pagination>
  </el-card>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      current: null,
      //本地默认头像
      levels: [],
      query: {
        pageIndex: 1,
        pageSize: 20,
        status: "2",
        order: {
          sort: "",
          fieldName: "",
        },
        q: "",
      },
      tableData: {
        counts: 0,
      },
      is_can_edit: false,
      is_can_del: false,
      is_can_halt: false,
    };
  },
  created() {
    this.loadData();

    this.is_can_edit = this.$power("seller/v1/goods/save");
    this.is_can_del = this.$power("seller/v1/goods/del");
    this.is_can_halt = this.$power("seller/v1/goods/halt");
  },
  methods: {
    /**
     * 搜索数据
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    /**
     * 加载数据
     */
    loadData() {
      this.loading = true;
      this.current = null;
      this.$http.post("seller/v1/goods", this.query).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
        this.loading = false;
      });
    },
    /**
     * 排序
     */
    onSort(e) {
      if (e.prop) {
        this.query.order.sort = e.order;
        this.query.order.fieldName = e.prop;
        this.loadData();
      }
    },
    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },
    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },
    /**
     * tab切换
     */
    statusChange() {
      this.loadData();
      // this.query.status = val;
    },
    /**
     * 修改
     * @param {*} e
     */
    onEdit(item) {
      this.$router.push({
        path: "/goods/edit",
        replace: true,
        query: { id: item.id, action: "update" },
      });
    },
    /**
     * 删除
     * @param {*} item
     */
    onDelete(item) {
      this.$http.get("seller/v1/goods/del?id=" + item.id).then((res) => {
        if (res.code == 0) {
          this.$message({ type: "success", message: res.msg });
          this.loadData();
        }
      });
    },

    /**
     *
     * @param {*} item
     */
    onDtl(item) {
      this.dtlItem = item;
    },

    /**
     * 下架
     */
    onHaltSale(item) {
      this.$http.get("seller/v1/goods/halt?id=" + item.id).then((res) => {
        if (res.code == 0) {
          this.$message({ type: "success", message: res.msg });
          this.loadData();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>